<template>
    <layout>
        <page-header :title="title" :items="items" />

        <div class="card-box">
            <div class="form-group">
                <button
                    class="btn btn-success btn-static waves-effect waves-light mb-1"
                    @click="onSave"
                >
                    <i class="fas fa-save mr-1" />
                    Save
                </button>
            </div>
            <h5 class="text-uppercase bg-light p-2 mt-0 mb-3">
                General
            </h5>
            <div class="form-group mb-3">
                <label for="warehouseName">
                    Name
                    <span class="text-danger">*</span>
                </label>
                <input
                    id="warehouseName"
                    v-model="warehouse.name"
                    type="text"
                    class="form-control"
                    placeholder="Name"
                />
            </div>
            <div class="form-group mb-3">
                <label for="warehousePostalCode">
                    Users
                </label>
                <v-select
                    v-model="usersCopy"
                    :multiple="true"
                    :filterable="false"
                    label="name"
                    :close-on-select="false"
                    :no-drop="!usersSearchResult.length"
                    :options="usersSearchResult"
                    @search="onUserSearch"
                />
            </div>
            <div class="form-group mb-3">
                <b-form-checkbox
                    v-model="warehouse.is_default"
                    name="enabled"
                    switch
                    :disabled="isDefault"
                >
                    Is Default
                </b-form-checkbox>
            </div>

            <h5 class="text-uppercase bg-light p-2 mt-0 mb-3">
                Address
            </h5>
            <div class="form-group mb-3">
                <label for="warehouseCompany">
                    Company
                    <span class="text-danger">*</span>
                </label>
                <input
                    id="warehouseCompany"
                    v-model="warehouse.company"
                    type="text"
                    class="form-control"
                    placeholder="Company"
                />
            </div>
            <div class="form-group mb-3">
                <label for="warehouseAddress">
                    Address
                    <span class="text-danger">*</span>
                </label>
                <input
                    id="warehouseAddress"
                    v-model="warehouse.address"
                    type="text"
                    class="form-control"
                    placeholder="Address"
                />
            </div>
            <div class="form-group mb-3">
                <label for="warehouseAddressLine">
                    Address Line 1
                </label>
                <input
                    id="warehouseAddressLine"
                    v-model="warehouse.address_line_1"
                    type="text"
                    class="form-control"
                    placeholder="Address Line 1"
                />
            </div>
            <div class="form-group mb-3">
                <label for="warehouseCity">
                    City
                    <span class="text-danger">*</span>
                </label>
                <input
                    id="warehouseCity"
                    v-model="warehouse.city"
                    type="text"
                    class="form-control"
                    placeholder="City"
                />
            </div>
            <div class="form-group mb-3">
                <label for="warehousePostalCode">
                    Postal Code
                    <span class="text-danger">*</span>
                </label>
                <input
                    id="warehousePostalCode"
                    v-model="warehouse.zip"
                    type="text"
                    class="form-control"
                    placeholder="Postal Code"
                />
            </div>
            <div class="form-group mb-3">
                <label>
                    State
                    <span class="text-danger">*</span>
                </label>
                <b-form-select
                    v-model="warehouse.state"
                    :options="states"
                />
            </div>
        </div>

        <div
            v-if="editMode"
            class="card-box"
        >
            <h5 class="text-uppercase bg-light p-2 mt-0 mb-3">
                States
            </h5>
            <div class="row">
                <div
                    v-for="(singleWarehouse, index) in warehouses"
                    :key="index"
                    class="col-3"
                >
                    <h4>
                        {{ singleWarehouse.name }}
                    </h4>
                    <draggable
                        class="list-group"
                        :list="singleWarehouse.states"
                        group="states"
                        @change="onChange"
                    >
                        <div
                            v-for="element in singleWarehouse.states"
                            :key="element.id"
                            class="list-group-item"
                        >
                            {{ element.name }}
                        </div>
                    </draggable>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import { mapActions } from 'vuex';
import Draggable from 'vuedraggable';
import appConfig from '@src/app.config';

export default {
    page: {
        title: 'Warehouse Details',
        meta: [{ name: 'description', content: appConfig.description }]
    },

    components: {
        Draggable
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            if (from.name === 'warehouse-add') {
                vm.$toaster('Warehouse has been created!');
            }
        });
    },

    data() {
        return {
            title: 'New Warehouse',
            items: [
                {
                    text: 'Upstep',
                    to: '/'
                },
                {
                    text: 'Warehouses',
                    to: '/warehouses'
                },
                {
                    text: 'Add',
                    active: true
                }
            ],
            editMode: false,
            orderProductColor: {
                history: []
            },
            warehouses: [],
            warehouse: {
                users: []
            },
            isDefault: false,
            id: null,
            usersCopy: [],
            usersSearchResult: [],
            states: [
                'Alabama',
                'Alaska',
                'Arizona',
                'Arkansas',
                'California',
                'Colorado',
                'Connecticut',
                'Delaware',
                'District Of Columbia',
                'Federated States Of Micronesia',
                'Florida',
                'Georgia',
                'Hawaii',
                'Idaho',
                'Illinois',
                'Indiana',
                'Iowa',
                'Kansas',
                'Kentucky',
                'Louisiana',
                'Maine',
                'Marshall Islands',
                'Maryland',
                'Massachusetts',
                'Michigan',
                'Minnesota',
                'Mississippi',
                'Missouri',
                'Montana',
                'Nebraska',
                'Nevada',
                'New Hampshire',
                'New Jersey',
                'New Mexico',
                'New York',
                'North Carolina',
                'North Dakota',
                'Ohio',
                'Oklahoma',
                'Oregon',
                'Palau',
                'Pennsylvania',
                'Rhode Island',
                'South Carolina',
                'South Dakota',
                'Tennessee',
                'Texas',
                'Utah',
                'Vermont',
                'Virginia',
                'Washington',
                'West Virginia',
                'Wisconsin',
                'Wyoming'
            ]
        };
    },

    watch: {
        usersCopy(users) {
            this.warehouse.users = [...users];
        }
    },

    async mounted() {
        this.id = this.$route.params.id;

        if (this.id) {
            this.editMode = true;
            this.title = 'Warehouse Details';
            this.items[this.items.length - 1].text = 'Details';

            this.fetchWarehouse();
        }

        await this.fetchWarehouses();

        this.onChange();
    },

    methods: {
        ...mapActions({
            getWarehouses: 'warehouses/index',
            getWarehouse: 'warehouses/show',
            createWarehouse: 'warehouses/store',
            updateWarehouse: 'warehouses/update',
            updateStates: 'warehouses/updateStates',
            getUsers: 'users/index',
        }),

        async fetchWarehouses() {
            try {
                const { rows } = await this.getWarehouses({
                    perPage: 100,
                    sortBy: 'created_at',
                    descending: false,
                });

                this.warehouses = rows;
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        },

        async fetchWarehouse() {
            try {
                this.warehouse = await this.getWarehouse(
                    this.id
                );

                if (this.warehouse.users.length) {
                    this.usersCopy = [...this.warehouse.users].map(user => ({
                        name: `${user.first_name} ${user.last_name} - ${user.email}`,
                        value: user.id
                    }));
                }

                if (this.warehouse.is_default) {
                    this.isDefault = true;
                }
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        },

        async onUserSearch(val) {
            if (!val) {
                this.usersSearchResult = [];

                return;
            }

            try {
                const { rows } = await this.getUsers({
                    perPage: 5,
                    page: 1,
                    search: val
                });

                this.usersSearchResult = [...rows].map(user => ({
                    name: `${user.first_name} ${user.last_name} - ${user.email}`,
                    value: user.id
                }));

            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        },

        async onSave() {
            try {
                const users = this.warehouse.users.map(item => item.value);

                if (!this.editMode) {
                    const createdWarehouse = await this.createWarehouse({
                        ...this.warehouse,
                        users
                    });

                    this.$toasterError();

                    this.$router.push(`/warehouses/${createdWarehouse.id}/edit`);
                } else {
                    const users = this.warehouse.users.map(item => item.value);

                    await this.updateWarehouse({
                        id: this.id,
                        warehouse: {
                            ...this.warehouse,
                            users
                        }
                    });

                    if (this.warehouse.is_default) {
                        this.isDefault = true;
                    }

                    await this.updateStates(this.warehouses);

                    this.$toaster('Warehouse has been updated!');
                }
            } catch (err) {
                console.error(err);

                this.$toasterError();
            }
        },

        onChange() {
            for (const warehouse of this.warehouses) {
                warehouse.states.sort((a, b) => a.name.localeCompare(b.name));
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.list-group {
    height: 100%;
    max-height: 691px;
    overflow-y: auto;
}
</style>
